import assets from './assets';
import bookings from './bookings';
import cards from './cards';
import chats from './chats';
import hostels from './hostels';
import notifications from './notifications';
import users from './users';

export default {
  assets,
  bookings,
  cards,
  chats,
  hostels,
  notifications,
  users,
};
