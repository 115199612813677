import duix from 'duix';
import config from 'config';
import utils from 'utils';

const baseUrl = config.api[window.location.hostname].baseUrl;

const image = body => {
  const headers = utils.fetch.getHeaders();
  delete headers['Content-Type'];

  return fetch(`${baseUrl}/admin/assets/upload/image`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${duix.get('hostel').token}`,
      ...headers,
    },
    body,
  }).then(utils.fetch.parseResponse);
};

export default {
  upload: {
    image,
  },
};
