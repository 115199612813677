import React, { useState, useEffect } from 'react';
import duix from 'duix';
import Button from 'UI/Button';
import CardModal from './CardModal';
import useInterval from 'utils/useInterval';
import actions from 'actions';
import { CARD_TYPE } from 'utils/consts';
import './styles.scss';

const TITLE = {
  infos: 'Info',
  discounts: 'Discount',
  events: 'Event',
};

const INFO = {
  infos:
    'With an Info card, you can share important information like WiFi passwords, dinner time, or any information that could be published on the front desk.',
  discounts:
    'Discount cards will allow you to publish partner stores or tour agencies. So you can create community and generate engagement with your guests.',
  events:
    'Here you can post your events, like parties, tours, games, etc. You can add start and end times, an image, and a description on these cards.',
};

const sortCards = (a, b) => {
  if (a.type === CARD_TYPE.EVENT) {
    const aDate = new Date(a.startsAt);
    const bDate = new Date(b.startsAt);
    return aDate.getTime() - bDate.getTime();
  } else {
    return a.name - b.name;
  }
};

const getCardDay = card => {
  let TODAY = new Date();
  TODAY.setHours(0, 0, 0, 0);
  TODAY = TODAY.getTime();

  const YESTERDAY = TODAY - 24 * 60 * 60 * 1000;
  const TOMORROW = TODAY + 24 * 60 * 60 * 1000;

  const cardDay = new Date(card.startsAt);
  cardDay.setHours(0, 0, 0, 0);

  if (cardDay.getTime() === TODAY) {
    return 'today';
  } else if (cardDay.getTime() === TOMORROW) {
    return 'tomorrow';
  } else if (cardDay.getTime() === YESTERDAY) {
    return 'yesterday';
  } else if (cardDay.getTime() < YESTERDAY) {
    return new Date(card.startsAt).toLocaleDateString();
  } else {
    return cardDay.toLocaleDateString();
  }
};

const getCardDate = card => {
  return `${getCardDay(card)} @ ${new Date(card.startsAt).toLocaleTimeString()}`;
};

const getCardClass = card => {
  if (card.type === CARD_TYPE.EVENT) {
    if (card.isFullDay) {
      const cardDay = new Date(card.startsAt);
      const todayAtStartOfDay = new Date();
      todayAtStartOfDay.setHours(0, 0, 0, 0);

      return cardDay.getTime() < todayAtStartOfDay.getTime() ? 'past' : 'future';
    } else {
      const cardDay = new Date(card.endsAt);
      return cardDay.getTime() > Date.now() ? 'future' : 'past';
    }
  } else {
    return 'future';
  }
};

const Cards = () => {
  const [cards, setCards] = useState({});
  //
  const [isCardModalShown, setIsCardModalShown] = useState(false);

  useEffect(() => {
    getData();

    const unsub = [
      duix.subscribe('cards', cards => {
        cards.infos.sort(sortCards);
        cards.discounts.sort(sortCards);
        cards.events.sort(sortCards);
        setCards(cards);
      }),
    ];

    return () => unsub.forEach(x => x());
  }, []);

  useInterval(() => {
    getData();
  }, 60 * 1000);

  const getData = async () => {
    const cards = await actions.cards.list();
    const infos = cards.filter(x => x.type === CARD_TYPE.INFO);
    const discounts = cards.filter(x => x.type === CARD_TYPE.DISCOUNT);
    const events = cards.filter(x => x.type === CARD_TYPE.EVENT);

    duix.set('cards', { infos, discounts, events });
  };

  const handleCardClick = card => () => {
    setIsCardModalShown(card);
  };

  return (
    <div className="Cards">
      {['infos', 'discounts', 'events'].map((type, i) => (
        <div key={type} className="Cards__cards">
          <div className="Cards__cards-wrapper">
            <div className="Cards__title">{TITLE[type]} cards</div>

            <Button onClick={() => setIsCardModalShown({ type: i + 1 })}>Add new</Button>

            {cards[type] && cards[type].length > 0 ? (
              cards[type].map(card => (
                <div
                  key={card.id}
                  className={`Cards__card ${getCardClass(card)}`}
                  onClick={handleCardClick(card, type)}
                >
                  {card.type === CARD_TYPE.EVENT && (
                    <div className="Cards__card-date">{getCardDate(card)}</div>
                  )}
                  <div className="Cards__card-name">{card.name}</div>
                </div>
              ))
            ) : (
              <>
                <div className="Cards__empty">No {TITLE[type]} cards created yet</div>
                <div className="Cards__empty">{INFO[type]}</div>
              </>
            )}
          </div>
        </div>
      ))}

      {isCardModalShown && (
        <CardModal data={isCardModalShown} onClose={() => setIsCardModalShown(false)} />
      )}
    </div>
  );
};

export default Cards;
