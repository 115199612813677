import React from 'react';
import c from 'classnames';
import './styles.scss';

const Button = ({
  isSecondary,
  isDanger,
  className = '',
  children,
  setRef,
  type = 'button',
  isDisabled,
  disabled,
  ...otherProps
}) => (
  <button
    ref={setRef}
    disabled={isDisabled}
    className={c('Button', className, { isSecondary, isDanger })}
    type={type}
    {...otherProps}
  >
    {children}
  </button>
);

export default Button;
