import duix from 'duix';
import config from 'config';
import utils from 'utils';

const baseUrl = config.api[window.location.hostname].baseUrl;

const register = params => {
  return fetch(`${baseUrl}/admin/hostels/register-with-email`, {
    method: 'POST',
    headers: {
      ...utils.fetch.getHeaders(),
    },
    body: JSON.stringify(params),
  }).then(utils.fetch.parseResponse);
};

const edit = params => {
  return fetch(`${baseUrl}/admin/hostels/edit`, {
    method: 'POST',
    headers: {
      ...utils.fetch.getHeaders(),
      Authorization: `Bearer ${duix.get('hostel').token}`,
    },
    body: JSON.stringify(params),
  }).then(utils.fetch.parseResponse);
};

const getMyHostel = params => {
  return fetch(`${baseUrl}/app/hostels/getMyHostel`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${duix.get('user').token}`,
      ...utils.fetch.getHeaders(),
    },
    body: JSON.stringify(params),
  }).then(utils.fetch.parseResponse);
};

export default {
  register,
  edit,
  getMyHostel,
};
