import React from 'react';

const options = { day: '2-digit', month: '2-digit', year: '2-digit' };
const style = { fontSize: '0.75em' };

export const getDateToShow = (date, withHtml = false) => {
  const TODAY = new Date();
  const TOMORROW = new Date(TODAY.getTime() + 24 * 60 * 60 * 1000);

  const value = date.toLocaleDateString(undefined, options);

  if (value === TODAY.toLocaleDateString(undefined, options)) {
    return withHtml ? (
      <>
        {value}
        <span style={style}> (Today)</span>
      </>
    ) : (
      `${value} (Today)`
    );
  } else if (value === TOMORROW.toLocaleDateString(undefined, options)) {
    return withHtml ? (
      <>
        {value}
        <span style={style}> (Tomorrow)</span>
      </>
    ) : (
      `${value} (Tomorrow)`
    );
  } else {
    const daysCount = Math.round((date.getTime() - Date.now()) / (24 * 60 * 60 * 1000));
    const textLabel = daysCount < 0 ? `~${daysCount * -1} days ago` : `in about ~${daysCount} days`;

    return withHtml ? (
      <>
        {value}
        <span style={style}> ({textLabel})</span>
      </>
    ) : (
      `${value} (${textLabel})`
    );
  }
};
