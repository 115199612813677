import React, { useState, useEffect } from 'react';
import Table from 'rc-table';
import duix from 'duix';
import Button from 'UI/Button';
import VerticalTable from 'UI/VerticalTable';
import BookingModal from './BookingModal';
import useInterval from 'utils/useInterval';
import { getDateToShow } from 'utils/dates';
import { BREAKPOINTS } from 'utils/consts';
import useWindowSize from 'hooks/useWindowSize';
import actions from 'actions';
import './styles.scss';

const KNOWN_SEARCHES = {
  TODAY: 'today',
  TOMORROW: 'tomorrow',
};

const getColumns = ({ setIsBookingModalShown }) => {
  return [
    {
      title: 'Username',
      key: 'id',
      render: value => (value.user.username ? value.user.username : '[Not defined yet]'),
    },
    {
      title: 'Email',
      render: value => value.user.email,
    },
    {
      title: 'Room',
      render: value => value.room || '?',
    },
    {
      title: 'Check-In',
      render: value => getDateToShow(new Date(value.checkInAt), true),
    },
    {
      title: 'Check-Out',
      render: value => getDateToShow(new Date(value.checkOutAt), true),
    },
    {
      title: 'Actions',
      render: (_value, obj) => (
        <div className="Bookings__action">
          <Button onClick={() => setIsBookingModalShown(obj)}>Review</Button>
        </div>
      ),
    },
  ];
};

const Bookings = () => {
  const windowSize = useWindowSize();
  const [searchText, setSearchText] = useState('');
  const [bookings, setBookings] = useState([]);
  const [isBookingModalShown, setIsBookingModalShown] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getData();
    const unsub = [duix.subscribe('bookings', bookings => setBookings(bookings))];
    return () => unsub.forEach(x => x());
  }, []);

  useInterval(() => {
    getData();
  }, 60 * 1000);

  const getData = async () => {
    const bookings = await actions.bookings.list();
    duix.set('bookings', bookings);
    setIsLoading(false);
  };

  const handleForceRefresh = () => {
    setIsLoading(true);
    getData();
  };

  const getBookings = isPending => {
    let bookingsToShow = bookings;
    const query = searchText.toLowerCase();

    bookingsToShow = bookingsToShow.filter(x => (isPending ? x._status === 1 : x._status === 2));

    if (Object.values(KNOWN_SEARCHES).indexOf(query) !== -1) {
      bookingsToShow = bookingsToShow.filter(
        x =>
          getDateToShow(new Date(x.checkInAt)).toLowerCase().indexOf(query) !== -1 ||
          getDateToShow(new Date(x.checkOutAt)).toLowerCase().indexOf(query) !== -1
      );
    } else if (query) {
      bookingsToShow = bookingsToShow.filter(
        x =>
          x.user.username.toLowerCase().indexOf(query) !== -1 ||
          x.user.email.toLowerCase().indexOf(query) !== -1 ||
          (x.room || '').toLowerCase().indexOf(query) !== -1
      );
    }

    bookingsToShow.sort((a, b) => a.checkInAt - b.checkInAt);

    return bookingsToShow;
  };

  return (
    <div className="Bookings">
      {isLoading && <div className="Bookings__loading">Loading...</div>}

      {!isLoading && (
        <>
          {bookings.length > 0 && (
            <div className="Bookings__main-actions">
              <input
                type="text"
                value={searchText}
                onChange={e => setSearchText(e.target.value)}
                // TODO: Add "Search by name, room or email. Try also Today and Tomorrow"
                placeholder="Search"
              />

              <button className="Button" onClick={handleForceRefresh}>
                Refresh
              </button>
            </div>
          )}

          {bookings.length > 0 && (
            <div className="Bookings__list">
              {windowSize.width > BREAKPOINTS.TABLET ? (
                <>
                  <h2>Pendings</h2>
                  <Table
                    columns={getColumns({ setIsBookingModalShown })}
                    data={getBookings(true)}
                    rowKey="id"
                  />

                  <h2>Approved</h2>
                  <Table
                    columns={getColumns({ setIsBookingModalShown })}
                    data={getBookings(false)}
                    rowKey="id"
                  />
                </>
              ) : (
                <>
                  <h2>Pendings</h2>
                  <VerticalTable
                    columns={getColumns({ setIsBookingModalShown })}
                    data={getBookings(true)}
                    rowKey="id"
                  />

                  <h2>Approved</h2>
                  <VerticalTable
                    columns={getColumns({ setIsBookingModalShown })}
                    data={getBookings(false)}
                    rowKey="id"
                  />
                </>
              )}
            </div>
          )}
        </>
      )}

      {!isLoading && bookings.length === 0 && (
        <div className="Bookings__no-yet">
          <h3>No Guests yet</h3>

          <div>You will see here the list of guests</div>
        </div>
      )}

      {isBookingModalShown && (
        <BookingModal
          data={isBookingModalShown}
          onClose={() => {
            getData();
            setIsBookingModalShown(false);
          }}
        />
      )}
    </div>
  );
};

export default Bookings;
