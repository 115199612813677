import React, { useState, useRef, useEffect } from 'react';
import Calendar from 'react-calendar';
import Dropdown from 'UI/Dropdown';
import { getDateToShow } from 'utils/dates';
import './styles.scss';

const HOURS = [...Array(24).keys()].map(x => ({ value: x, label: x }));
const MINUTES = [...Array(6).keys()].map(x => ({
  value: x * 10,
  label: (x * 10).toString().padStart(2, 0),
}));

const DateColumn = ({ label, value, setValue, onlyDate }) => {
  const calendarRef = useRef();
  const [isCalendarShown, setIsCalendarShown] = useState(false);

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    const handleClickOutside = e => {
      if (isCalendarShown) {
        if (calendarRef.current && !calendarRef.current.contains(e.target)) {
          setIsCalendarShown(false);
        }
      }
    };

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [calendarRef, isCalendarShown]);

  return (
    <div className="DateColumn">
      <div className="DateColumn__day">
        <label>{label}</label>
        <input
          required
          readOnly
          type="text"
          placeholder="Date"
          value={getDateToShow(value)}
          onClick={() => setIsCalendarShown(true)}
        />
      </div>

      {!onlyDate && (
        <div className="DateColumn__time">
          <div>
            <label>Hour</label>
            <Dropdown
              value={value.getHours()}
              options={HOURS}
              onChange={e => {
                const newDate = new Date(value);
                newDate.setHours(+e.target.value);
                setValue(newDate);
              }}
            />
          </div>

          <div>
            <label>Minutes</label>
            <Dropdown
              value={value.getMinutes()}
              options={MINUTES}
              onChange={e => {
                const newDate = new Date(value);
                newDate.setMinutes(+e.target.value);
                setValue(newDate);
              }}
            />
          </div>
        </div>
      )}

      {isCalendarShown && (
        <div ref={calendarRef} className="DateColumn__calendar">
          <Calendar
            value={value}
            onChange={date => {
              date.setHours(value.getHours(), value.getMinutes(), 0, 0);
              setValue(date);
              setIsCalendarShown(false);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default DateColumn;
