import api from 'api';

const image = async file => {
  const formdata = new FormData();
  formdata.append('image', file);

  const response = await api.assets.upload.image(formdata);
  return response.data.asset;
};

export default {
  upload: {
    image,
  },
};
