import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import duix from 'duix';
import Error from 'components/Error';
import Button from 'UI/Button';
import actions from 'actions';
import './styles.scss';

const FORM = {
  REGISTER: 'register',
  LOGIN: 'login',
};

const Login = () => {
  const history = useHistory();
  const [emailLogin, setEmailLogin] = useState('');
  const [passwordLogin, setPasswordLogin] = useState('');

  const [hostelNameRegister, setHostelNameRegister] = useState('');
  const [emailRegister, setEmailRegister] = useState('');
  const [passwordRegister, setPasswordRegister] = useState('');
  const [passwordRegister2, setPasswordRegister2] = useState('');

  const [errorLogin, setErrorLogin] = useState('');
  const [errorRegister, setErrorRegister] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmitLogin = async e => {
    e.preventDefault();

    if (!emailLogin || !passwordLogin) {
      setErrorLogin('Please enter Email and Password');
      return;
    }

    setIsLoading(FORM.LOGIN);
    await doLogin({ email: emailLogin, password: passwordLogin, setError: setErrorLogin });
    setIsLoading(false);
  };

  const doLogin = async ({ email, password, setError }) => {
    try {
      const user = await actions.users.login({ email, password });
      duix.set('user', user);

      const hostel = await actions.hostels.getMyHostel();

      if (!hostel.isAdmin) {
        setError('You have not access to this tool. Your account has been reported.');
        return;
      }

      duix.set('hostel', hostel);

      history.push('/guests');
      window.scrollTo(0, 0);
    } catch (error) {
      setError(error.message);
    }
  };

  const handleSubmitRegister = async e => {
    e.preventDefault();

    if (!hostelNameRegister) {
      setErrorRegister('Please enter the Hostel Name');
      return;
    }

    if (!emailRegister) {
      setErrorRegister('Please enter the Admin Email');
      return;
    }

    if (!passwordRegister || !passwordRegister2) {
      setErrorRegister('Please enter the Password');
      return;
    }

    if (passwordRegister !== passwordRegister2) {
      setErrorRegister('Passwords should be the same');
      return;
    }

    setIsLoading(FORM.REGISTER);

    try {
      await actions.hostels.register({
        hostelName: hostelNameRegister,
        email: emailRegister,
        password: passwordRegister,
      });

      await doLogin({
        email: emailRegister,
        password: passwordRegister,
        setError: setErrorRegister,
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setErrorRegister(error.message);
    }
  };

  return (
    <div className="Login">
      <div className="left">
        <form className="form" onSubmit={handleSubmitRegister}>
          <h2>Create Hostel Account</h2>
          <input
            type="text"
            placeholder="Hostel name"
            value={hostelNameRegister}
            onChange={e => setHostelNameRegister(e.target.value)}
          />
          <input
            type="email"
            placeholder="Email"
            value={emailRegister}
            onChange={e => setEmailRegister(e.target.value)}
          />
          <input
            type="password"
            placeholder="Password"
            value={passwordRegister}
            onChange={e => setPasswordRegister(e.target.value)}
          />
          <input
            type="password"
            placeholder="Repeat password"
            value={passwordRegister2}
            onChange={e => setPasswordRegister2(e.target.value)}
          />
          {!!errorRegister && <Error message={errorRegister} />}

          <div className="disclaimer">
            1 month free trial <b>(no card required)</b>.
          </div>

          <div className="disclaimer">
            By creating an account, you accept the{' '}
            <a href="https://getlobee.com/privacy-policy" target="_blank" rel="noopener noreferrer">
              <u>Privacy Policy</u>
            </a>{' '}
            and{' '}
            <a href="https://getlobee.com/terms-of-use" target="_blank" rel="noopener noreferrer">
              <u>Terms of Use</u>
            </a>
          </div>

          <Button type="submit" isDisabled={isLoading === FORM.REGISTER}>
            {isLoading === FORM.REGISTER ? 'Loading' : 'Create Account'}
          </Button>
        </form>
      </div>

      <div className="right">
        <form className="form" onSubmit={handleSubmitLogin}>
          <h2>Log In as a Hostel</h2>

          <input
            type="email"
            placeholder="Email"
            value={emailLogin}
            onChange={e => setEmailLogin(e.target.value)}
          />

          <input
            type="password"
            placeholder="Password"
            value={passwordLogin}
            onChange={e => setPasswordLogin(e.target.value)}
          />

          {!!errorLogin && <Error message={errorLogin} />}

          <Button type="submit" isDisabled={isLoading === FORM.LOGIN}>
            {isLoading === FORM.LOGIN ? 'Loading' : 'Login'}
          </Button>
        </form>
      </div>
    </div>
  );
};

export default Login;
