import api from 'api';

const getSent = async () => {
  const response = await api.notifications.getSent();
  return response.data.history;
};

const schedule = async params => {
  const response = await api.notifications.schedule(params);
  return response.data;
};

export default {
  getSent,
  schedule,
};
