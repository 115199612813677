import duix from 'duix';
import api from 'api';

const login = async params => {
  const response = await api.users.login(params);
  return response.data.user;
};

const edit = async params => {
  const response = await api.users.edit(params);
  return response.data;
};

const logout = () => {
  duix.set('user', null);
  duix.set('hostel', null);
};

const getList = async params => {
  const response = await api.users.getList(params);
  return response.data.users;
};

const uploadAvatar = photo => {
  const formdata = new FormData();
  formdata.append('photo', photo);

  return api.users
    .uploadAvatar(formdata)
    .then(r => r.data)
    .then(data => {
      const user = duix.get('user');

      if (!user.avatarIndex) {
        user.avatarIndex = Math.round(Math.random() * 100000);
      } else {
        user.avatarIndex++;
      }

      duix.set('user', user);

      return data;
    });
};

export default {
  login,
  edit,
  logout,
  getList,
  uploadAvatar,
};
