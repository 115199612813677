import duix from 'duix';
import config from 'config';
import utils from 'utils';

const baseUrl = config.api[window.location.hostname].baseUrl;

const get = ({ lastTimestamp }) => {
  return fetch(`${baseUrl}/app/chats/myHostel/${lastTimestamp}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${duix.get('hostel').token}`,
      ...utils.fetch.getHeaders(),
    },
  }).then(utils.fetch.parseResponse);
};

const getLastTimestamp = () => {
  return fetch(`${baseUrl}/app/chats/myHostel/getLastTimestamp`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${duix.get('hostel').token}`,
      ...utils.fetch.getHeaders(),
    },
  }).then(utils.fetch.parseResponse);
};

const send = params => {
  return fetch(`${baseUrl}/app/chats/myHostel/send`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${duix.get('hostel').token}`,
      ...utils.fetch.getHeaders(),
    },
    body: JSON.stringify(params),
  }).then(utils.fetch.parseResponse);
};

const remove = ({ id }) => {
  return fetch(`${baseUrl}/app/chats/myHostel/remove`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${duix.get('hostel').token}`,
      ...utils.fetch.getHeaders(),
    },
    body: JSON.stringify({ id }),
  }).then(utils.fetch.parseResponse);
};

export default {
  get,
  getLastTimestamp,
  send,
  remove,
};
