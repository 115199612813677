import React, { useEffect } from 'react';
import closeIcon from 'styles/icons/close.svg';
import './styles.scss';

const Modal = ({ title = '', onClose, children }) => {
  useEffect(() => {
    const logKey = e => {
      if (e.key === 'Escape') {
        onClose();
      }
    };

    document.addEventListener('keydown', logKey);

    return () => document.removeEventListener('keydown', logKey);
  }, [onClose]);

  return (
    <div className="Modal" onClick={onClose}>
      <div className="Modal__wrapper" onClick={e => e.stopPropagation()}>
        <div className="Modal__header">
          <span className="Modal__title">{title}</span>
          <span className="Modal__close" onClick={onClose}>
            <img src={closeIcon} alt="Close" />
          </span>
        </div>
        <div className="Modal__content">{children}</div>
      </div>
    </div>
  );
};

export default Modal;
