/*eslint react-hooks/exhaustive-deps: off*/

import React, { useState, useEffect, Fragment } from 'react';
import c from 'classnames';
import duix from 'duix';
import useInterval from 'utils/useInterval';
import Button from 'UI/Button';
import removeIcon from 'styles/icons/remove.svg';
import actions from 'actions';
import { TEXT_ACTION } from 'utils/consts';
import './styles.scss';

const removeRepeated = array => {
  return [...new Set(array)];
};

let isProcessingQueue = false;
let lastUserId = '';

const Chat = () => {
  const [hostel, setHostel] = useState(duix.get('hostel'));
  const [users, setUsers] = useState([]);
  const [text, setText] = useState('');
  const [texts, setTexts] = useState([]);
  const [queue, setQueue] = useState([]);
  const [queueSent, setQueueSent] = useState([]);
  const [lastTimestamp, setLastTimestamp] = useState(0);
  //
  const [isChatLoaded, setIsChatLoaded] = useState(false);

  useEffect(() => {
    const unsub = [duix.subscribe('hostel', hostel => setHostel(hostel))];
    return () => unsub.forEach(x => x());
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const localCleared = texts.filter(x => !x.isTemporal);
        const lastLocalTime =
          localCleared.length > 0 ? localCleared[localCleared.length - 1].createdAt : 0;

        if (lastLocalTime > 0 && lastLocalTime === lastTimestamp) {
          return;
        }

        const textsFromBackend = await actions.chats.get({ lastTimestamp: lastLocalTime });

        // Should restart?
        if (isChatLoaded && lastLocalTime > 0) {
          const shouldRestart = textsFromBackend.find(x => x.action === TEXT_ACTION.RESTART_ADMIN);

          if (shouldRestart) {
            restart();
            return;
          }
        }

        const newTexts = [...localCleared, ...textsFromBackend];
        newTexts.sort((a, b) => a.createdAt - b.createdAt);

        setTexts(newTexts.reverse().slice(0, 200).reverse());

        hydrateUsers(textsFromBackend);

        if (!isChatLoaded) {
          setIsChatLoaded(true);
        }

        setTimeout(scrollDown, 0);
      } catch (error) {
        console.log('$$ error', error);
      }
    })();
  }, [lastTimestamp]);

  useInterval(() => {
    (async () => {
      // It's gonna get the lastTimeStamp only if
      // the chat was already loaded (with initial chats)
      if (isChatLoaded) {
        const lastTimestampInBackend = await actions.chats.getLastTimestamp();

        if (lastTimestampInBackend !== lastTimestamp) {
          setLastTimestamp(lastTimestampInBackend);
        }
      }
    })();
  }, 5000);

  const restart = () => {
    setTexts([]);
    setIsChatLoaded(false);
    setLastTimestamp(0);
  };

  const hydrateUsers = async texts => {
    const ids = removeRepeated(
      texts
        .filter(x => x.userId !== hostel.admin)
        .map(x => x.userId)
        .filter(x => !!x)
    );

    const missingUsers = ids.filter(id => !users.find(x => x.id === id));

    if (missingUsers.length === 0) {
      return;
    }

    const newUsers = await actions.users.getList({ ids: missingUsers });
    setUsers([...users, ...newUsers]);
  };

  const scrollDown = () => {
    const container = document.querySelector('.Chat__texts');

    if (container) {
      container.scrollTop = container.scrollHeight;
    }
  };

  const getUsername = text => {
    if (text.userId === hostel.admin) {
      return hostel.name;
    }

    const user = users.find(x => x.id === text.userId);

    if (user) {
      return user.username || user.email;
    } else {
      return `[Loading]`;
    }
  };

  const handleKeyUp = async e => {
    if (!isChatLoaded) {
      return;
    }

    if (e.key === 'Enter' && text) {
      doSend();
    }
  };

  const doSend = () => {
    const textToSend = text;
    setText('');

    const hostel = duix.get('hostel');

    setQueue([
      ...queue,
      {
        id: Math.trunc(Math.random() * 1000000000),
        text: textToSend,
        createdAt: Date.now(),
        userId: hostel.admin,
        isTemporal: true,
      },
    ]);

    setTimeout(scrollDown, 0);
  };

  useInterval(() => {
    (async () => {
      if (isProcessingQueue) {
        return;
      }

      isProcessingQueue = true;

      const queueSentIds = queueSent.map(x => x.id);
      const textsToSend = queue.filter(x => queueSentIds.indexOf(x.id) === -1);

      if (textsToSend.length === 0) {
        isProcessingQueue = false;
        return;
      }

      try {
        const sent = await actions.chats.send({ texts: textsToSend.map(x => x.text) });

        // Hydrate the ids
        sent.forEach(item => {
          const x = textsToSend.find(x => !x.realId && x.text === item.text);
          x.realId = item.id;
        });

        setQueueSent([...queueSent, ...textsToSend]);
      } catch (error) {
        // TODO: Catch error
      }

      isProcessingQueue = false;
    })();
  }, 1000);

  const handleRemoveText = async text => {
    const yesRemoveIt = window.confirm(
      'Are you sure you want to remove this message? This action can not be undone'
    );

    if (yesRemoveIt) {
      try {
        await actions.chats.remove(text);
        const newTexts = texts.map(x => (x.id === text.id ? { ...x, isRemoved: true } : x));
        setTexts(newTexts);
      } catch (error) {
        alert(
          'There was an error while trying to remove this message. Get in contact with support'
        );
      }
    }
  };

  const getTexts = () => {
    const received = texts.filter(x => !x.isHidden);
    const noReceivedYet = queue.filter(x => !queueSent.find(y => y.id === x.id));
    const noSentYet = queueSent.filter(x => !texts.find(y => y.id === x.realId));

    const items = [...received, ...noReceivedYet, ...noSentYet];
    items.sort((a, b) => a.createdAt - b.createdAt);

    return items;
  };

  lastUserId = '';

  return (
    <div className="Chat">
      <div className="Chat__texts">
        {texts.length > 0 &&
          getTexts().map(text => {
            const showUser = lastUserId !== text.userId;
            lastUserId = text.userId;

            return (
              <Fragment key={text.id}>
                {showUser && <div className="Chat__booking">{getUsername(text)}:</div>}

                <div
                  className={c('Chat__text', {
                    '--reported': text.isReported,
                    '--removed': text.isRemoved,
                  })}
                >
                  {text.text}
                  {text.isTemporal && '...'}

                  {text.isReported && !text.isRemoved && (
                    <span className="Chat__text-reported">REPORTED</span>
                  )}
                  {text.isRemoved && <span className="Chat__text-removed">REMOVED</span>}

                  {!text.isRemoved && (
                    <img
                      className="Chat__text-remove"
                      onClick={() => handleRemoveText(text)}
                      src={removeIcon}
                      alt="Remove text"
                    />
                  )}
                </div>
              </Fragment>
            );
          })}
      </div>

      <div className="Chat__send">
        <input
          type="text"
          placeholder="Write your message here. Press ENTER to send."
          value={text}
          onChange={e => setText(e.target.value)}
          onKeyUp={handleKeyUp}
        />

        <Button onClick={doSend}>Send</Button>
      </div>
    </div>
  );
};

export default Chat;
