import api from 'api';

const list = async () => {
  const response = await api.bookings.list();
  return response.data.bookings;
};

const edit = async params => {
  const response = await api.bookings.edit(params);
  return response.data;
};

export default {
  list,
  edit,
};
