import React, { useState, useRef } from 'react';
import duix from 'duix';
// https://www.npmjs.com/package/react-calendar
import Button from 'UI/Button';
import Modal from 'UI/Modal';
import Error from 'components/Error';
import DateColumn from './DateColumn';
import actions from 'actions';
import { CARD_TYPE } from 'utils/consts';
import config from 'config';
import './styles.scss';

const CardModal = ({ data, onClose }) => {
  const isEditing = !!data.id;
  const type = data.type;
  const imageInputRef = useRef();
  const [name, setName] = useState(data.name ? data.name : '');
  const [description, setDescription] = useState(data.description ? data.description : '');
  const [image, setImage] = useState(data.image ? data.image : false);
  const [isFullDay, setIsFullDay] = useState(data.isFullDay ? data.isFullDay : false);
  //
  const CALENDAR_TIME = new Date();
  CALENDAR_TIME.setMinutes(0);
  CALENDAR_TIME.setSeconds(0);
  CALENDAR_TIME.setMilliseconds(0);
  const [startsAt, setStartsAt] = useState(data.startsAt ? new Date(data.startsAt) : CALENDAR_TIME);
  const [endsAt, setEndsAt] = useState(data.endsAt ? new Date(data.endsAt) : CALENDAR_TIME);
  //
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isUploadingImage, setIsUploadingImage] = useState(false);

  const handleSubmit = async e => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const card = {
        name,
        type,
      };

      if (isEditing) {
        card.id = data.id;
      }

      if (description || data.description) {
        card.description = description;
      }

      if (image || image === null) {
        card.image = image;
      }

      if (type === CARD_TYPE.EVENT) {
        if (!isFullDay && startsAt.getTime() > endsAt.getTime()) {
          setError('The event should ends after the begining time');
          setIsLoading(false);
          return;
        }

        if (isFullDay) {
          card.startsAt = startsAt.getTime();
          card.isFullDay = true;
        } else {
          card.startsAt = startsAt.getTime();
          card.endsAt = endsAt.getTime();
        }
      }

      const newCard = await actions.cards.add(card);
      const cards = duix.get('cards');

      // Edit or Create?
      if (isEditing) {
        duix.set('cards', {
          infos: cards.infos.map(x => (x.id === newCard.id ? newCard : x)),
          discounts: cards.discounts.map(x => (x.id === newCard.id ? newCard : x)),
          events: cards.events.map(x => (x.id === newCard.id ? newCard : x)),
        });
      } else {
        if (newCard.type === CARD_TYPE.INFO) {
          cards.infos.push(newCard);
        } else if (newCard.type === CARD_TYPE.DISCOUNT) {
          cards.discounts.push(newCard);
        } else if (newCard.type === CARD_TYPE.EVENT) {
          cards.events.push(newCard);
        }

        duix.set('cards', cards);
      }

      onClose();
    } catch (error) {
      setError(error.message);
      setIsLoading(false);
    }
  };

  const handleRemoveCard = async () => {
    const card = data;

    const shouldRemove = window.confirm('Are you sure?');
    if (shouldRemove) {
      await actions.cards.remove(card);

      const cards = duix.get('cards');
      if (card.type === CARD_TYPE.INFO) {
        cards.infos = cards.infos.filter(x => x.id !== card.id);
      } else if (card.type === CARD_TYPE.DISCOUNT) {
        cards.discounts = cards.discounts.filter(x => x.id !== card.id);
      } else if (card.type === CARD_TYPE.EVENT) {
        cards.events = cards.events.filter(x => x.id !== card.id);
      }

      duix.set('cards', cards);
      onClose();
    }
  };

  const handleIsFullDayChange = e => {
    const newStartsAt = new Date(startsAt.getTime());
    newStartsAt.setHours(0, 0, 0, 0);

    setIsFullDay(e.target.checked);
    setStartsAt(newStartsAt);
  };

  const handleImageChange = async e => {
    setIsUploadingImage(true);
    const files = Array.from(e.target.files);
    const image = files[0];
    const asset = await actions.assets.upload.image(image);
    setImage(asset.id);
    setIsUploadingImage(false);
  };

  return (
    <Modal title="Creating Card" onClose={onClose}>
      <div className="CardModal">
        <form onSubmit={handleSubmit}>
          <label>Name</label>
          <input
            required
            autoFocus
            type="text"
            placeholder="Name"
            value={name}
            onChange={e => setName(e.target.value)}
          />

          <label>
            Description <span className="optional">(optional)</span>
          </label>
          <textarea
            type="text"
            placeholder="Description"
            value={description}
            onChange={e => setDescription(e.target.value)}
          />

          {type === CARD_TYPE.EVENT && (
            <>
              <div className="CardModal__full-day">
                <input
                  type="checkbox"
                  checked={isFullDay}
                  id="isFullDay"
                  onChange={handleIsFullDayChange}
                />
                <label htmlFor="isFullDay">It's a full day event</label>
              </div>

              <div className="CardModal__dates">
                <DateColumn
                  onlyDate={isFullDay}
                  label={isFullDay ? 'Event day' : 'Starts at'}
                  value={startsAt}
                  setValue={setStartsAt}
                />

                {!isFullDay && <DateColumn label="Ends at" value={endsAt} setValue={setEndsAt} />}
              </div>
            </>
          )}

          <label>
            Image <span className="optional">(optional)</span>
          </label>

          <div
            className="CardModal__image"
            onClick={() => (image ? setImage(null) : imageInputRef.current.click())}
            style={{
              backgroundImage: `url(${
                image ? `${config.firebase.storage}/assets/${image}.jpg` : null
              })`,
            }}
          >
            {image ? (
              <div className="CardModal__image-remove">x</div>
            ) : isUploadingImage ? (
              'Uploading...'
            ) : (
              'Click here to upload'
            )}
          </div>

          <input
            ref={imageInputRef}
            readOnly
            className="CardModal__image-input"
            type="file"
            placeholder="Image"
            value=""
            accept="image/png, image/jpeg"
            onChange={handleImageChange}
          />

          {!!error && <Error message={error} />}

          <div className="CardModal__buttons">
            {!!isEditing && (
              <Button isDanger type="button" isDisabled={isLoading} onClick={handleRemoveCard}>
                {isLoading ? 'Loading' : 'Remove'}
              </Button>
            )}

            <Button type="submit" isDisabled={isLoading}>
              {isLoading ? 'Loading' : 'Save'}
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default CardModal;
