import React, { useState, useRef } from 'react';
import duix from 'duix';
import Error from 'components/Error';
import Button from 'UI/Button';
import Modal from 'UI/Modal';
import actions from 'actions';
import config from 'config';
import avatarImage from './avatar.png';
import './styles.scss';

const { PDF_TYPES } = actions.hostels;

const Settings = () => {
  const avatarRef = useRef();
  const hostel = duix.get('hostel');
  const user = duix.get('user');

  const [avatarIndex, setAvatarIndex] = useState(user.avatarIndex || 0);
  const [hostelName, setHostelName] = useState(hostel.name);

  const [newPassword, setNewPassword] = useState('');
  const [newPassword2, setNewPassword2] = useState('');

  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isDownloadingPDF, setIsDownloadingPDF] = useState(false);
  const [isPDFModalOpen, setIsPDFModalOpen] = useState(false);

  const handleSubmit = async e => {
    e.preventDefault();

    const hostel = duix.get('hostel');
    const user = duix.get('user');

    const isChangingUserPassword = !!newPassword;
    const isChangingHostelName = hostel.name !== hostelName;

    if (isChangingUserPassword) {
      if (!newPassword) {
        setError('Please enter a new Password');
        return;
      }

      if (newPassword !== newPassword2) {
        setError('Passwords should be the same');
        return;
      }
    }

    setIsLoading(true);

    try {
      if (isChangingHostelName) {
        const newHostelData = {};

        if (hostelName) {
          newHostelData.name = hostelName;
        }

        await actions.hostels.edit(newHostelData);
        duix.set('hostel', { ...hostel, ...newHostelData });
      }

      if (isChangingUserPassword) {
        const newUserData = {};

        if (newPassword) {
          newUserData.password = newPassword;
        }

        await actions.users.edit(newUserData);
        duix.set('user', { ...user, ...newUserData });
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setError(error.message);
    }
  };

  const handleUploadAvatar = async e => {
    setIsUploading(true);
    await actions.users.uploadAvatar(e.target.files[0]);
    const user = duix.get('user');
    setIsUploading(false);
    setAvatarIndex(user.avatarIndex);
  };

  const handleDownloadPDF = type => () => {
    setIsPDFModalOpen(false);
    setIsDownloadingPDF(true);
    setTimeout(() => {
      actions.hostels.generatePDF(type);
      setIsDownloadingPDF(false);
    }, 500);
  };

  const avatar = !avatarIndex
    ? avatarImage
    : `${config.firebase.storage}/avatars/${user.id}.jpg?avatarIndex=${avatarIndex}`;

  return (
    <div className="Settings">
      <div className="left">
        <form className="form" onSubmit={handleSubmit}>
          <h2>Change data</h2>

          <label>Hostel Name</label>
          <input
            type="text"
            placeholder="Hostel name"
            value={hostelName}
            onChange={e => setHostelName(e.target.value)}
          />

          <label>New Password</label>
          <input
            type="password"
            placeholder="New password"
            value={newPassword}
            onChange={e => setNewPassword(e.target.value)}
          />

          {newPassword && (
            <>
              <label>Repeat New Password</label>
              <input
                type="password"
                placeholder="Repeat New password"
                value={newPassword2}
                onChange={e => setNewPassword2(e.target.value)}
              />
            </>
          )}

          {!!error && <Error message={error} />}

          <Button type="submit" isDisabled={isLoading}>
            {isLoading ? 'Loading' : 'Save'}
          </Button>
        </form>
      </div>

      <div className="right">
        <form className="form">
          <div className="Settings__avatar" style={{ backgroundImage: `url(${avatar})` }}></div>

          <div>
            <Button isDisabled={isUploading} onClick={() => avatarRef.current.click()}>
              {isUploading ? 'Uploading...' : 'Upload a new avatar'}
            </Button>
            <input
              key={avatarIndex}
              className="Settings__avatar-input"
              ref={avatarRef}
              type="file"
              name="image"
              accept=".png,.jpeg,.jpg"
              onChange={handleUploadAvatar}
            />
          </div>
        </form>

        <div>
          <Button isDisabled={isDownloadingPDF} onClick={() => setIsPDFModalOpen(true)}>
            {isDownloadingPDF ? 'Downloading...' : 'Download Welcoming PDF'}
          </Button>
        </div>
      </div>

      {isPDFModalOpen && (
        <Modal title="Choose a PDF" onClose={() => setIsPDFModalOpen(false)}>
          <div className="Settings__pdfs">
            <div className="Settings__pdf" onClick={handleDownloadPDF(PDF_TYPES.COLOR_FULL)}>
              <img src="/pdf/background/color-full.png" alt="Color Full" />
              <div>Color - Full</div>
            </div>

            <div className="Settings__pdf" onClick={handleDownloadPDF(PDF_TYPES.COLOR_ECO)}>
              <img src="/pdf/background/color-eco.png" alt="Color Eco" />
              <div>Color - Eco</div>
            </div>

            {/* <div className="Settings__pdf" onClick={handleDownloadPDF(PDF_TYPES.BLACK_FULL)}>
              <img src="/pdf/background/black-full.png" alt="Black Full" />
              <div>Black - Full</div>
            </div>

            <div className="Settings__pdf" onClick={handleDownloadPDF(PDF_TYPES.BLACK_ECO)}>
              <img src="/pdf/background/black-eco.png" alt="Black Eco" />
              <div>Black - Eco</div>
            </div> */}
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Settings;
