import duix from 'duix';
import config from 'config';
import utils from 'utils';

const baseUrl = config.api[window.location.hostname].baseUrl;

const getSent = () => {
  return fetch(`${baseUrl}/admin/notifications/getSent`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${duix.get('hostel').token}`,
      ...utils.fetch.getHeaders(),
    },
  }).then(utils.fetch.parseResponse);
};

const schedule = params => {
  return fetch(`${baseUrl}/admin/notifications/schedule`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${duix.get('hostel').token}`,
      ...utils.fetch.getHeaders(),
    },
    body: JSON.stringify(params),
  }).then(utils.fetch.parseResponse);
};

export default {
  getSent,
  schedule,
};
