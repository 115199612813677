import React, { useState, useRef, useEffect } from 'react';
import duix from 'duix';
import c from 'classnames';
// https://www.npmjs.com/package/react-calendar
import Calendar from 'react-calendar';
import Button from 'UI/Button';
import Modal from 'UI/Modal';
import Error from 'components/Error';
import actions from 'actions';
import { getDateToShow } from 'utils/dates';
import './styles.scss';

const CALENDAR = {
  CHECK_IN: 'checkin',
  CHECK_OUT: 'checkout',
};

const BookingModal = ({ data, onClose }) => {
  const isRemoved = !data.user.email;
  const saveButtonRef = useRef();
  const calendarRef = useRef();
  const [email, setEmail] = useState(data ? data.user.email : '');
  const [checkInAt, setCheckInAt] = useState(data ? new Date(data.checkInAt) : new Date());
  const [checkOutAt, setCheckOutAt] = useState(
    data ? new Date(data.checkOutAt) : new Date().addDays(2)
  );
  const [room, setRoom] = useState(data ? data.room : '');
  //
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [calendarShown, setCalendarShown] = useState(false);

  const isPending = data._status === 1;

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    const handleClickOutside = e => {
      if (calendarShown) {
        if (calendarRef.current && !calendarRef.current.contains(e.target)) {
          setCalendarShown(false);
        }
      }
    };

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [calendarRef, calendarShown]);

  const handleDateChange = date => {
    if (calendarShown === CALENDAR.CHECK_IN) {
      setCheckInAt(date);
    } else {
      setCheckOutAt(date);
      saveButtonRef.current.focus();
    }

    setCalendarShown(false);
  };

  const handleSubmit = async e => {
    e.preventDefault();

    if (!data) {
      const bookings = duix.get('bookings');

      if (bookings.find(x => x.user.email === email)) {
        setError('This user is already booked');
        return;
      }
    }

    try {
      setIsLoading(true);

      const newBooking = {
        id: data.id,
        email,
        checkInAt: checkInAt.getTime(),
        checkOutAt: checkOutAt.getTime(),
        room,
      };

      await actions.bookings.edit(newBooking);

      const bookings = duix.get('bookings');
      duix.set(
        'bookings',
        bookings.map(x => (x.id === data.id ? { ...data, ...newBooking } : x))
      );

      onClose();
    } catch (error) {
      setError(error.message);
      setIsLoading(false);
    }
  };

  const handleBanChat = async () => {
    const doIt = window.confirm('Are you sure?');

    if (doIt) {
      const newBooking = {
        id: data.id,
        checkInAt: checkInAt.getTime(),
        checkOutAt: checkOutAt.getTime(),
        room,
        isBanned: !data.isBanned,
      };

      await actions.bookings.edit(newBooking);

      const bookings = duix.get('bookings');
      duix.set(
        'bookings',
        bookings.map(x => (x.id === data.id ? { ...data, ...newBooking } : x))
      );

      onClose();
    }
  };

  const handleReject = async () => {
    setIsLoading(true);
    const newBooking = {
      id: data.id,
      checkInAt: checkInAt.getTime(),
      checkOutAt: checkOutAt.getTime(),
      room,
      _status: 3,
    };

    await actions.bookings.edit(newBooking);
    setIsLoading(false);
    onClose();
  };

  const handleApprove = async () => {
    setIsLoading(true);
    const newBooking = {
      id: data.id,
      checkInAt: checkInAt.getTime(),
      checkOutAt: checkOutAt.getTime(),
      room,
      _status: 2,
    };

    await actions.bookings.edit(newBooking);
    setIsLoading(false);
    onClose();
  };

  return (
    <Modal title="Stay info" onClose={onClose}>
      <div className="BookingModal">
        <form onSubmit={handleSubmit}>
          {!!isRemoved && <div className="BookingModal__no-exists">This user no longer exists</div>}

          <label>Username</label>
          <input
            className="BookingModal__username"
            readOnly
            type="text"
            placeholder="This field will be defined by the user"
          />

          <label>Email</label>
          <input
            className="BookingModal__email"
            required
            autoFocus
            readOnly={isRemoved}
            type="email"
            placeholder="Email"
            value={email}
            onChange={e => setEmail(e.target.value)}
          />

          <div className="BookingModal__dates">
            <div className="BookingModal__date">
              <label>Check-in date</label>
              <input
                required
                readOnly
                type="text"
                placeholder="Check-in date"
                value={getDateToShow(checkInAt)}
                onClick={() => setCalendarShown(CALENDAR.CHECK_IN)}
              />
            </div>

            <div className="BookingModal__date">
              <label>Check-out date</label>
              <input
                required
                readOnly
                type="text"
                placeholder="Check-out date"
                value={getDateToShow(checkOutAt)}
                onClick={() => setCalendarShown(CALENDAR.CHECK_OUT)}
              />
            </div>
          </div>

          <label>
            Room <span className="optional">(optional)</span>
          </label>
          <input
            className="BookingModal__room"
            readOnly={isRemoved}
            type="text"
            placeholder="Room"
            value={room}
            onChange={e => setRoom(e.target.value)}
          />

          {!!error && <Error message={error} />}

          {isPending && (
            <div className="BookingModal__buttons">
              <Button isDanger={!data.isBanned} onClick={handleReject} isDisabled={isLoading}>
                Reject
              </Button>

              <Button
                setRef={saveButtonRef}
                onClick={handleApprove}
                isDisabled={isLoading || isRemoved}
              >
                {isLoading ? 'Loading' : 'Approve'}
              </Button>
            </div>
          )}

          {!isPending && (
            <div className="BookingModal__buttons">
              <Button
                isDanger={!data.isBanned}
                onClick={handleBanChat}
                isDisabled={isLoading || isRemoved}
              >
                {data.isBanned ? 'Give access to Chat' : 'Ban access to Chat'}
              </Button>

              {!isRemoved && (
                <Button setRef={saveButtonRef} type="submit" isDisabled={isLoading || isRemoved}>
                  {isLoading ? 'Loading' : 'Save'}
                </Button>
              )}
            </div>
          )}

          {calendarShown && (
            <div
              ref={calendarRef}
              className={c('BookingModal__calendar', {
                'BookingModal__calendar--checkin': calendarShown === CALENDAR.CHECK_IN,
                'BookingModal__calendar--checkout': calendarShown === CALENDAR.CHECK_OUT,
              })}
            >
              <Calendar
                minDate={calendarShown === CALENDAR.CHECK_IN ? undefined : checkInAt}
                maxDate={calendarShown === CALENDAR.CHECK_IN ? checkOutAt : undefined}
                onChange={handleDateChange}
                value={calendarShown === CALENDAR.CHECK_IN ? checkInAt : checkOutAt}
              />
            </div>
          )}
        </form>
      </div>
    </Modal>
  );
};

export default BookingModal;
