export const USER_TYPE = {
  HOSTEL: 1,
  GUEST: 2,
};

export const CARD_TYPE = {
  INFO: 1,
  DISCOUNT: 2,
  EVENT: 3,
};

export const TEXT_ACTION = {
  RESTART_GUEST_CLIENT: 1,
  RESTART_ADMIN: 2,
};

export const BREAKPOINTS = {
  MOBILE: 425,
  TABLET: 768,
};
