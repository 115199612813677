export default {
  api: {
    localhost: {
      baseUrl: 'http://localhost:5000/getlobee/us-central1',
    },
    'admin.getlobee.com': {
      baseUrl: 'https://us-central1-getlobee.cloudfunctions.net',
    },
  },
  firebase: {
    storage: 'https://storage.googleapis.com/getlobee.appspot.com',
  },
};
