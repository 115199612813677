import duix from 'duix';
import 'jspdf/dist/polyfills.es.js';
import jsPDF from 'jspdf';
import api from 'api';

const register = async params => {
  const r = await api.hostels.register(params);
  return r.data;
};

const edit = async params => {
  const r = await api.hostels.edit(params);
  return r.data;
};

const getMyHostel = async () => {
  const r = await api.hostels.getMyHostel();
  const hostel = r.data.hostel;

  // Mappeo
  hostel.events = hostel.events ? Object.values(hostel.events) : [];
  return hostel;
};

const getSecretCode = () => {
  const hostel = duix.get('hostel');
  return hostel.secretCode;
};

const PDF_TYPES = {
  COLOR_FULL: 'color-full',
  COLOR_ECO: 'color-eco',
  BLACK_FULL: 'black-full',
  BLACK_ECO: 'black-eco',
};

const generatePDF = pdfType => {
  const doc = new jsPDF({ format: 'a4' });
  const docWidth = doc.internal.pageSize.getWidth();
  const docHeight = doc.internal.pageSize.getHeight();

  // Add background
  doc.addImage(`/pdf/background/${pdfType}.png`, 'png', 0, 0, docWidth, docHeight);

  // Add QR
  const qrSize = 50;
  doc.addImage(
    `/pdf/qr/download.png`,
    'png',
    docWidth / 2 - qrSize / 2,
    docHeight - 121,
    qrSize,
    qrSize
  );

  // Add hostel code
  doc.setFontSize(19);
  doc.text(getSecretCode().split('').join(' '), docWidth / 2, docHeight - 13.5, {
    align: 'center',
  });

  // Download
  doc.save(`${pdfType}.pdf`);
};

export default {
  edit,
  register,
  getMyHostel,
  getSecretCode,
  PDF_TYPES,
  generatePDF,
};
