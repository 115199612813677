import React from 'react';

const VerticalTable = ({ columns, data, rowKey = 'id' }) => {
  if (!Array.isArray(data) || data.length === 0) {
    return 'No Data';
  }

  return (
    <div className="VerticalTable">
      {data.map(item => (
        <div key={item[rowKey]} className="VerticalTable__row">
          {columns.map(column => (
            <div key={column.title} className="VerticalTable__cell">
              <span className="VerticalTable__header">{column.title}:</span>{' '}
              {column.render(item, item)}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default VerticalTable;
