import React, { useState, useEffect, useRef } from 'react';
import { Link, withRouter } from 'react-router-dom';
import c from 'classnames';
import duix from 'duix';
import logo from 'styles/imgs/logo.png';
import actions from 'actions';
import './styles.scss';

const PAGES = [
  // { label: 'Home', url: '/home' },
  { label: 'Guests', url: '/guests' },
  { label: 'Cards', url: '/cards' },
  { label: 'Chat', url: '/chat' },
  { label: 'Notifications', url: '/notifications' },
];

const Header = ({ history }) => {
  const dropdownRef = useRef();
  const [isMenuShown, setIsMenuShown] = useState(false);
  const isLogged = !!duix.get('user');

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    const handleClickOutside = e => {
      if (isMenuShown) {
        if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
          setIsMenuShown(false);
        }
      }
    };

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef, isMenuShown]);

  const handleLogout = () => {
    actions.users.logout();
  };

  return (
    <div className="Header">
      <Link to="/">
        <div className="logo">
          <img src={logo} alt="Lobee logo" />
          <div className="lobee">lobee</div>
        </div>
      </Link>

      <nav className="menu">
        {isLogged && (
          <>
            {PAGES.map(page => (
              <Link
                key={page.url}
                className={c('item', { '--active': history.location.pathname === page.url })}
                to={page.url}
              >
                {page.label}
              </Link>
            ))}

            <div className="item --options" onClick={() => setIsMenuShown(!isMenuShown)}>
              <span>
                .<br />.<br />.
              </span>

              {isMenuShown && (
                <div ref={dropdownRef} className="dropdown">
                  {PAGES.map(page => (
                    <Link
                      key={page.url}
                      className={c('--mobile', {
                        '--active': history.location.pathname === page.url,
                      })}
                      to={page.url}
                    >
                      {page.label}
                    </Link>
                  ))}

                  <Link to="/settings">Settings</Link>

                  <Link onClick={handleLogout} to="/">
                    Log out
                  </Link>
                </div>
              )}
            </div>
          </>
        )}
      </nav>
    </div>
  );
};

export default withRouter(Header);
