import api from 'api';

const list = async () => {
  const response = await api.cards.list();
  return response.data.cards;
};

const add = async params => {
  const response = await api.cards.add(params);
  return response.data.card;
};

const remove = async params => {
  const response = await api.cards.remove(params);
  return response.data;
};

export default {
  list,
  add,
  remove,
};
