import duix from 'duix';
import config from 'config';
import utils from 'utils';

const baseUrl = config.api[window.location.hostname].baseUrl;

const login = user => {
  return fetch(`${baseUrl}/app/v3/user/login`, {
    method: 'POST',
    headers: utils.fetch.getHeaders(),
    body: JSON.stringify(user),
  }).then(utils.fetch.parseResponse);
};

const edit = user => {
  return fetch(`${baseUrl}/app/users/edit`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${duix.get('user').token}`,
      ...utils.fetch.getHeaders(),
    },
    body: JSON.stringify(user),
  }).then(utils.fetch.parseResponse);
};

const getList = params => {
  return fetch(`${baseUrl}/app/users/list`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${duix.get('hostel').token}`,
      ...utils.fetch.getHeaders(),
    },
    body: JSON.stringify(params),
  }).then(utils.fetch.parseResponse);
};

const uploadAvatar = params => {
  const headers = utils.fetch.getHeaders();
  delete headers['Content-Type'];

  return fetch(`${baseUrl}/app/users/avatar/upload`, {
    method: 'POST',
    headers: {
      ...headers,
      Authorization: `Bearer ${duix.get('hostel').token}`,
    },
    body: params,
  }).then(utils.fetch.parseResponse);
};

export default {
  login,
  edit,
  getList,
  uploadAvatar,
};
