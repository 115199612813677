import duix from 'duix';
import config from 'config';
import utils from 'utils';

const baseUrl = config.api[window.location.hostname].baseUrl;

const list = () => {
  return fetch(`${baseUrl}/admin/bookings/list`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${duix.get('hostel').token}`,
      ...utils.fetch.getHeaders(),
    },
    body: JSON.stringify(),
  }).then(utils.fetch.parseResponse);
};

const edit = booking => {
  return fetch(`${baseUrl}/admin/bookings/edit`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${duix.get('hostel').token}`,
      ...utils.fetch.getHeaders(),
    },
    body: JSON.stringify(booking),
  }).then(utils.fetch.parseResponse);
};

export default {
  list,
  edit,
};
