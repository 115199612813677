import React from 'react';
import './styles.scss';

const Home = () => {
  return (
    <div className="Home">
      <h1>Welcome!</h1>
    </div>
  );
};

export default Home;
