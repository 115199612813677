import duix from 'duix';
import config from 'config';
import utils from 'utils';

const baseUrl = config.api[window.location.hostname].baseUrl;

const list = card => {
  return fetch(`${baseUrl}/app/cards/list`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${duix.get('hostel').token}`,
      ...utils.fetch.getHeaders(),
    },
    body: JSON.stringify(card),
  }).then(utils.fetch.parseResponse);
};

const add = card => {
  return fetch(`${baseUrl}/admin/cards/add`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${duix.get('hostel').token}`,
      ...utils.fetch.getHeaders(),
    },
    body: JSON.stringify(card),
  }).then(utils.fetch.parseResponse);
};

const remove = card => {
  return fetch(`${baseUrl}/admin/cards/remove`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${duix.get('hostel').token}`,
      ...utils.fetch.getHeaders(),
    },
    body: JSON.stringify(card),
  }).then(utils.fetch.parseResponse);
};

export default {
  list,
  add,
  remove,
};
