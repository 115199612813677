import React, { useState, useEffect } from 'react';
import Error from 'components/Error';
import Button from 'UI/Button';
import useInterval from 'utils/useInterval';
import actions from 'actions';
import './styles.scss';

const ONE_HOUR = 60 * 60 * 1000;

const Notifications = () => {
  const [history, setHistory] = useState([]);
  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getData();
    window.alert(`Hands off!\n
Please be aware that the notifications will be received by all the users.`);
  }, []);

  useInterval(() => {
    getData();
  }, 60 * 1000);

  const getData = async () => {
    const history = await actions.notifications.getSent();
    history.sort((a, b) => b.createdAt - a.createdAt);
    setHistory(history);
  };

  const handleSubmit = async e => {
    e.preventDefault();

    if (history && history[0]) {
      const lastHistory = history[0];

      if (lastHistory.createdAt > Date.now() - ONE_HOUR) {
        setError('We allow only 1 notification per hour');
        return;
      }
    }

    const doIt = window.confirm('Are you sure you want to send this notification?');

    if (!doIt) {
      return;
    }

    if (!title) {
      setError('Title is required');
      return;
    }

    setError('');
    setIsLoading(true);

    await actions.notifications.schedule({
      title,
      body,
    });

    setHistory([{ title, body, createdAt: Date.now() }, ...history]);

    setIsLoading(false);
  };

  return (
    <div className="Notifications">
      <div className="Notifications__left">
        {history.length === 0 && (
          <div className="Notifications__clue">You will see your sent notifications here.</div>
        )}
        {history.map(item => (
          <div key={item.createdAt} className="Notifications__history">
            <div>
              <span>Title:</span> {item.title}
            </div>
            <div>
              <span>Body:</span> {item.body}
            </div>
            <div>
              <span>Sent:</span> {new Date(item.createdAt).toLocaleString()}
            </div>
          </div>
        ))}
      </div>
      <div className="Notifications__right">
        <form onSubmit={handleSubmit}>
          <label>
            Title <span className="optional">(60 chars max)</span>
          </label>
          <input
            required
            autoFocus
            type="text"
            placeholder="Notification title"
            value={title}
            onChange={e => setTitle(e.target.value)}
            maxLength={60}
          />

          <label>
            Body <span className="optional">(optional) (140 chars max)</span>
          </label>
          <textarea
            type="text"
            placeholder="Notification body"
            value={body}
            onChange={e => setBody(e.target.value)}
            maxLength={140}
          />

          {!!error && <Error message={error} />}

          <Button type="submit" isDisabled={isLoading}>
            {isLoading ? 'Sending' : 'Send'}
          </Button>
        </form>
      </div>
    </div>
  );
};

export default Notifications;
