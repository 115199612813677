import 'react-calendar/dist/Calendar.css';
import 'utils/sentry';
import 'utils/polyfills';
import React, { Component, Fragment } from 'react';
import duix from 'duix';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import ReactGA from 'react-ga';
import './styles/base.scss';
import Header from 'components/Header';
import SubHeader from 'components/SubHeader';
import Login from 'pages/Login';
import Chat from 'pages/Chat';
import Cards from 'pages/Cards';
import Bookings from 'pages/Bookings';
import Home from 'pages/Home';
import Notifications from 'pages/Notifications';
import Settings from 'pages/Settings';
import utils from 'utils';

ReactGA.initialize('UA-159283495-1');

duix.set('user', utils.storage.local.get('user'));
duix.set('hostel', utils.storage.local.get('hostel'));
duix.set('bookings', utils.storage.local.get('bookings') || []);
duix.set('cards', utils.storage.local.get('cards') || []);
duix.subscribe('user', user => utils.storage.local.set('user', user));
duix.subscribe('hostel', hostel => utils.storage.local.set('hostel', hostel));
duix.subscribe('bookings', bookings => utils.storage.local.set('bookings', bookings || []));
duix.subscribe('cards', cards => utils.storage.local.set('cards', cards || []));

const withAnalytics = Component => props => {
  ReactGA.pageview(window.location.pathname + window.location.search);
  return <Component {...props} />;
};

class App extends Component {
  state = {
    user: duix.get('user'),
    hostel: duix.get('hostel'),
  };

  componentDidMount() {
    duix.subscribe('user', user => {
      this.setState({ user });
    });

    duix.subscribe('hostel', hostel => {
      this.setState({ hostel });
    });
  }

  render() {
    const { user, hostel } = this.state;
    const isLogged = !!user && !!hostel;

    if (!isLogged) {
      return (
        <BrowserRouter basename="/">
          <Fragment>
            <Header />

            <Switch>
              <Route path="/" component={withAnalytics(Login)} />
            </Switch>
          </Fragment>
        </BrowserRouter>
      );
    }

    return (
      <BrowserRouter basename="/">
        <Fragment>
          <Header />
          <SubHeader />

          <Switch>
            <Route path="/home" component={withAnalytics(Home)} />
            <Route path="/chat" component={withAnalytics(Chat)} />
            <Route path="/cards" component={withAnalytics(Cards)} />
            <Route path="/login" component={withAnalytics(Login)} />
            <Route path="/guests" component={withAnalytics(Bookings)} />
            <Route path="/notifications" component={withAnalytics(Notifications)} />
            <Route path="/settings" component={withAnalytics(Settings)} />
            <Route path="/" component={withAnalytics(Bookings)} />
          </Switch>
        </Fragment>
      </BrowserRouter>
    );
  }
}

export default App;
