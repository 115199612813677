import api from 'api';

const get = async params => {
  const response = await api.chats.get(params);
  return response.data.chat;
};

const getLastTimestamp = async () => {
  const response = await api.chats.getLastTimestamp();
  return response.data.lastTimestamp;
};

const send = async params => {
  const response = await api.chats.send(params);
  return response.data.message ? response.data.message : response.data.messages;
};

const remove = params => {
  return api.chats.remove(params);
};

export default {
  get,
  getLastTimestamp,
  send,
  remove,
};
