import React, { useState } from 'react';
import c from 'classnames';
import closeIcon from 'styles/icons/close.svg';
import './styles.scss';

const Info = ({ children }) => {
  const [isHidenInMobile, setIsHidenInMobile] = useState(false);

  return (
    <div className="Info" onClick={() => setIsHidenInMobile(false)}>
      <div className="Info__symbol">?</div>
      {children && (
        <div
          className={c('Info__children', { 'Info__children--is-hiden-in-mobile': isHidenInMobile })}
        >
          <div
            className="Info__close"
            onClick={e => {
              e.stopPropagation();
              setIsHidenInMobile(true);
            }}
          >
            <img src={closeIcon} alt="Close" />
          </div>
          {children}
        </div>
      )}
    </div>
  );
};

export default Info;
