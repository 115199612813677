import React from 'react';
import { withRouter } from 'react-router-dom';
import Info from '../Info';

import './styles.scss';

const PAGES = [
  {
    title: 'Guests',
    url: '/guests',
    info: (
      <span>
        Every time a guest checks in to your Hostel, add a new Stay here.
        <br />
        This way they would be able to:
        <ul>
          <li>Access the Chat.</li>
          <li>See the cards with events, discounts, and important information on the app.</li>
          <li>Receive your custom notifications on their phones.</li>
        </ul>
      </span>
    ),
  },
  {
    title: 'Cards',
    url: '/cards',
    info: (
      <span>
        These cards will be displayed on the app timeline, so the users
        <br />
        will see all the events happening in your hostel, the important
        <br />
        information, and will have access to discounts and offers on
        <br />
        different stores and tours.
      </span>
    ),
  },
  {
    title: 'Chat',
    url: '/chat',
    info: <span>This chat is public. Every active stay can read it.</span>,
  },
  {
    title: 'Notifications',
    url: '/notifications',
    info: (
      <span>
        Push notifications are the ideal solution to engage users and
        <br />
        keep them informed about what's happening on your hostel.
        <br />
        <br />
        <b>But be careful!</b> Too many notifications could be annoying for
        <br />
        the users so, pay attention.
        <br />
        We recommend <b>no more than one notification per day</b>
        .
        <br />
        <br />
        i.e: "Some guests just organized a Pictionary game! We need more people!"
      </span>
    ),
  },
  { title: 'Settings', url: '/settings' },
];

const SubHeader = ({ history }) => {
  const page = PAGES.find(x => history.location.pathname.startsWith(x.url)) || PAGES[0];

  return (
    <div className="SubHeader">
      <div className="SubHeader__wrapper">
        <div className="SubHeader__title">{page.title}</div>
        {page.info && <Info>{page.info}</Info>}
      </div>
    </div>
  );
};

export default withRouter(SubHeader);
